import React, { Component } from "react";
import { Link } from "gatsby";

import Layout from "../layouts/ja";
import SEO from "../components/seo";

export default class TermsAndConditionsPage extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const specific_date = new Date("2022-01-09");
    const current_date = new Date();
    if (current_date.getTime() > specific_date.getTime()) {
      return (
        <Layout location={this.props.location}>
          <SEO
            title="規約と条件 "
            keyword="規約と条件"
            decription="規約と条件"
          />

          <div className="terms">
            <div className="container container--1000">
              <h1 className="text--center">規約と条件</h1>
              <p className="p--sm">
                アヤナリワードは、アヤナ コモド ワエチチュ ビーチ、アヤナ
                ラコディア、アヤナ ヴィラズ バリ、アヤナ セガラ バリ、アヤナ
                リゾート バリ、リンバ ｂｙ アヤナ バリ、アヤナ ミッドプラザ
                ジャカルタ、デロニクス ホテル
                カラワンに滞在されたお客様に、特典としてご利用いただける、メンバーシッププログラムです。以下の利用規約は、アヤナリワードの会員を保護するためのものです。お問合せに関しては、メンバーシップサービス
                <a href="mailto:info@ayanarewards.com">info@ayanarewards.com</a>
                にメールもしくは、
                <a href="ayanarewards.com">ayanarewards.com</a>
                にログオンください。
              </p>
              <h3>会員資格</h3>
              <ol>
                <li>
                  すべてのゲストは、アヤナ
                  リワードに入会し会員となることができます。アヤナ
                  リワードの会員権は、他人へ譲渡することはできません。会員は、複数のアカウントを保有し、ポイントを獲得することはできません。
                </li>
                <li>
                  会員は、ポイント獲得すると、よりランクの高い会員ランクに到達することができます。会員ランクは、1年間有効です。
                </li>
                <li>
                  年間5,000ポイントを獲得した会員は、ゴールドにアップグレードされます。ゴールド会員は、ゴールドのステータスと特典を維持するために、翌年に年間1,500ポイントを獲得する必要があります。年間10,000ポイントを獲得した会員は、プラチナにアップグレードされます。プラチナ会員は、プラチナのステータスと特典を維持するために、
                  翌年に年間5,000ポイントを獲得する必要があります。会員ランク維持に必要なポイントを獲得できなかった場合は、会員ランクがダウングレードされます。
                </li>
                <li>
                  アヤナリワードポイントは、発行日から10年または120ヶ月間有効です。
                </li>
                <li>
                  アヤナリワードのアカウントは、12ヶ月以上ログインが確認されなかった場合、12ヶ月以内にウェブサイトへのアクセス、関連するホテルでの滞在、またはご利用がなかった場合は、無効となります。
                </li>
                <li>
                  アヤナ リワード
                  アカウントが無効になると、ポイントの有効期限に関係なく、蓄積されたポイントは失効します。
                </li>
                <li>
                  アヤナ
                  リワードは、ゲストからの情報が正確かつ完全に提供されていない場合、所定の理由なしにメンバーシップを終了したり申請を拒否する権利を有しています。
                </li>
              </ol>
              <h3>獲得ポイント</h3>
              <ol>
                <li>
                  会員は、各参加ホテルでRp 10,000利用毎に1アヤナ
                  リワードポイントを獲得できます。ただし、デロニクス ホテル
                  カラワンでは、Rp 15,000毎に1アヤナ
                  リワードポイントの獲得となります。Rp
                  0以下の端数のポイントは切り捨てられます。
                </li>
                <li>
                  アヤナ
                  リワードゴールド会員は、対象となる料金の支払いで10％のボーナスポイントを獲得でき、プラチナ会員は対象となる料金の支払いで25％のボーナスポイントを獲得する事ができます。
                </li>
                <li>
                  アヤナリワードポイントは、対象となる料金で滞在し宿泊料金を支払う会員にのみ適用されます。また、複数の客室のうちの1室に会員本人が滞在し、各部屋の料金をチェックアウト時に会員自らが支払いを行う場合、同一期間に滞在する参加施設で最大5室分のポイントを獲得できます。
                </li>
                <li>
                  他社サイト（GDSを除く）から宿泊予約を行った場合、支払った料金の金額に関わらず宿泊分のアヤナリワードポイントを受け取る事はできません。滞在中に、ホテル内のポイント対象施設を利用し且つそれをお部屋付けにて支払った分のポイントは獲得することができます。
                </li>
                <li>
                  ポイントは、アヤナリワード会員になった後に獲得することができます。ポイントは、チェックアウト後7営業日以内に会員のアカウントに加算されます。
                </li>
                <li>
                  ポイント獲得の対象となる料金：
                  <ul>
                    <li>
                      アヤナミッドプラザジャカルタ
                      <ul>
                        <li>
                          客室利用料金。ただし、従業員料金、手数料の発生する料金、エージェント料金、無料宿泊特典、航空会社クルー用料金、現金＆ポイント料金、マスターアカウントに請求される客室料金、グループ料金、などの料金は対象外となります。ポイント対象外の客室料金で滞在した場合でも、ポイント対象となるその他のホテル内施設を利用した場合、それらの利用分のポイントは獲得できます。
                        </li>
                        <li>部屋付けにした追加の利用料金（ミニバーを除く）</li>
                        <li>飲食料金（HonZenおよびKaDOレストランを除く）</li>
                        <li>スパ利用</li>
                      </ul>
                    </li>
                    <li>
                      デロニクスホテルカラワン
                      <ul>
                        <li>
                          客室利用料金。ただし、無料宿泊特典や現金＆ポイント料金などの料金は対象外となります。
                        </li>
                        <li>部屋付けにした追加の利用料金</li>
                        <li>飲食料金（サカナレストランを除く）</li>
                        <li>スパ利用</li>
                        <li>
                          ランドリー、ドライクリーニング、ビジネスセンター、車代、紛失および破損時の手数料
                        </li>
                      </ul>
                    </li>
                    <li>
                      アヤナ ヴィラズ バリ、アヤナ セガラ バリ、アヤナ リゾート
                      バリ、リンバ ｂｙ アヤナ バリ、アヤナ コモド ワエチチュ
                      ビーチ
                      <ul>
                        <li>
                          公式サイトやホテルへの直接予約の宿泊料金。特別料金など一部除外あり。
                        </li>
                        <li>部屋付けにした追加の利用料金（ミニバーを除く）</li>
                        <li>飲食料金（HonZenレストランを除く）</li>
                        <li>スパ料金</li>
                      </ul>
                    </li>
                    <li>
                      アヤナ ラコ ディア号
                      <ul>
                        <li>
                          公式サイトや直接予約の場合のプログラムパッケージ料金と宿泊チャーター料金。特別料金およびアルコール飲料等の追加費用は除きます。
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <br />
                  **税金とサービス料、およびその他の付随的費用は、アヤナ
                  リワードポイントの対象にはなりません。
                </li>
                <li>
                  会員は、アヤナ
                  リワードメンバーポータル（ayanarewards.com）にログインして、アヤナ
                  リワードポイントの残高と利用状況を確認するか、 アヤナ
                  リワードメンバーシップサービスマネージャー（info@ayanarewards.com）にメールを送り頂くと最新のご利用情報を確認いただけます。
                </li>
                <li>
                  アヤナ
                  リワードメンバーアカウントのポイント残高に不足がみられる場合、チェックアウト日から30日以内にアヤナ
                  リワード会員サービスマネージャーにご連絡ください。ご連絡いただけなかった場合、その不足分のアヤナ
                  リワードポイントが付与されなくなります。会員は、チェックアウト後7営業日でポイントを確認できます。
                  アヤナ リワードポイントが不足しているという申し立ては、info @
                  ayanarewards.com宛てにメールでご連絡ください。
                </li>
                <li>
                  アヤナリワードは、ポイントが誤ってアカウントにクレジットされた場合、メンバーのアカウント残高からポイントを差し引く権利を留保します。
                </li>
              </ol>
              <h3>ポイントの交換</h3>
              <ol>
                <li>
                  会員は、ayanarewards.comのメンバーポータル–特典/特典交換セクションでアヤナリワードポイントを特典と交換できます。
                  アヤナリワードは、会員のユーザーIDとパスワードに加えて、確認のために他の個人情報の提供を求める権利を有しています。
                </li>
                <li>
                  最小アヤナリワードポイントレベルが、各リワードに適用されます。ポイント交換要件については、ウェブサイトのリワードチャートをご確認ください。
                </li>
                <li>
                  リワードを請求する前に、各リワードに記載されている利用規約を常に参照することは会員の義務となります。
                </li>
                <li>
                  リワードポイント交換による宿泊は、info @
                  ayanarewards.comに希望の滞在日を記載したメールを送信し、事前予約が必要となります。ハイシーズン期間中は追加料金がかかる場合がありますのでご注意ください。
                </li>
                <li>
                  交換した全てのリワードは、返金およびキャンセルできません。会員のポイントは、リワードとの交換時にに差し引かれ、ポイント交換規約に従います。
                </li>
                <li>
                  交換したリワードは、償還日から最大6か月間有効です。未使用のリワードは、会員のアカウントにクレジットとして返却したり、現金と交換したりすることはできません。
                </li>
                <li>
                  アヤナリワードは、提供されるリワードに影響を与える可能性のあるリワード交換先のパートナーが、プログラムからの撤回またはパートナーのキャンセルした場合、その責任を負いません。
                </li>
              </ol>
              <h3>一般規約</h3>
              <ol>
                <li>
                  会員は、個人情報に変更があった場合は、会員ポータルで会員記録を更新するか、アヤナリワードメンバーシップサービスに書面で通知する責任があります。
                </li>
                <li>
                  リワードの証明書を安全に保管することは、会員自身の責任です。証明書は、再発行できませんのでご注意ください。
                </li>
                <li>
                  アヤナリワード会員サービスオフィスは、処理エラーによる不正確な会員明細またはアヤナリワードポイントの見越または残高を修正する権利を留保します。
                </li>
                <li>
                  アヤナリワードは、規則、制限、プログラム特典、および特別オファーを予告なしに修正する権利を留保します。
                  アヤナリワードは、いつでもアヤナリワードプログラムを終了する権利を留保します。
                </li>
                <li>
                  アヤナリワードポイントの獲得およびリワードの償還は、適用されるすべての法律および規制の対象となります。
                </li>
                <li>
                  アヤナリワードの下で受け取った特典とリワードは、税金の対象となる場合があります。そのような税金は、上記の利益または報酬の受領者である会員の唯一の責任です。
                </li>
                <li>
                  アヤナリワードは、明示または黙示を問わず、いかなる保証または表明も行わず、アヤナリワードを通じて請求、償還、および/または享受される商品またはサービスの品質に関する一切の責任（結果として生じる損害を含む）を明示的に否認します。法律によって暗示されるすべての条件、保証、またはその他の条件は、適用される法律および規制によって許可される最大限の範囲で除外されます。メンバーが特典証明書の使用を譲渡する場合、この条項は譲受人にも適用されるものとします。
                </li>
                <li>
                  参加ホテルおよび/またはアヤナリワードは、変化する市況を反映するために必要に応じてアヤナリワードポイント値を調整する権利を留保します。
                </li>
                <li>
                  アヤナリワードまたはリワードの使用に関する詐欺または乱用は、参加ホテルおよび/またはアヤナリワード参加パートナーによる適切な管理上および/または法的措置の対象となります。
                </li>
                <li>このプログラムは、法律で禁止された場合は無効です。</li>
                <li>
                  アヤナリワードロイヤリティープログラムは、参加ホテルにより運営されるプログラムです。サービス提供者は、会員の記録にもアクセスする場合があります。
                </li>
                <li>
                  プログラム利用規約のすべての解釈は、アヤナリワードの独自の裁量によるものとします。紛争が発生した場合、メンバーはインドネシアの裁判所の専属管轄権に従うことに同意します。
                </li>
              </ol>
            </div>
          </div>
        </Layout>
      );
    } else {
      return (
        <Layout location={this.props.location}>
          <SEO
            title="ご利用規約及び条件 "
            keyword="ご利用規約及び条件"
            decription="ご利用規約及び条件"
          />

          <div className="terms">
            <div className="container container--1000">
              <h1 className="text--center">ご利用規約及び条件</h1>
              <p className="p--sm">
                アヤナリワードはアヤナコモドリゾート ワエチチュ
                ビーチ、アヤナラコディア号、ザ ヴィラズ アット アヤナリゾート
                バリ、アヤナリゾート＆スパ バリ、リンバジンバラン
                バリbyアヤナ、アヤナミッドプラザ ジャカルタ、デロニクスホテル
                カラワンをご利用のゲストの皆様へ、日頃のご愛顧への感謝の気持ちを込めてご用意しております。
                下記にご案内する規則と規約は、アヤナリワード会員の保護のため設けられております。内容にご不明な点がございましたら、
                <a href="mailto:info@ayanarewards.com">info@ayanarewards.com</a>
                もしくは　
                <a href="https://ayanarewards.com">ayanarewards.com</a>
                よりお気軽に会員サービスまでご連絡ください。
              </p>
              <h3>会員資格</h3>
              <ol>
                <li>
                  全てのお客様にアヤナリワード会員にご入会いただけます。アヤナリワード会員の権利は、他人に譲渡することは出来ません。会員は、複数の口座で会員を維持したり、ポイントを獲得したりしてはいけません。
                </li>
                {/* <li>
                    <span style={{color: "rgb(34, 34, 34)", fontFamily: "arial, sans-serif", fontSize: "16px", whiteSpace: "preWrap", backgroundColor: "rgb(248, 249, 250)"}}>会員資格は2年以内に有効です。これらの年に活動がない場合、メンバーのアカウントは無効になり、累積されたポイントはすべて失効します。</span></li> */}
                <li>
                  ポイントが貯まると、よりステータスの高い会員になることができます。会員レベルは1年間有効です。年間5,000ポイントを集めるとゴールドにアップグレードされますが、ゴールドステータスを維持するためには年間1,500ポイントが必要となります。年間10,000ポイントを集めるとプラチナにアップグレードされ、プラチナステータスを維持するためには年間5,000ポイントが必要となります。上記のポイントが貯まらない場合は、会員レベルのダウングレードとなります。
                </li>
                <li>
                  アヤナリワードはお申込み時に必要かつ正確な情報の提示がなされなかった場合、通知なく会員の受付を拒否/停止する権利を有します。
                </li>
              </ol>
              <h3>ポイント獲得</h3>
              <ol>
                <li>
                  会員の方は、加盟ブランドの対象となる料金を10,000ルピアのご利用ごとにアヤナリワード1ポイントを獲得できます。デロニックス
                  カラワンホテル アンド
                  レジデンスでは、15,000ルピアのご利用ごとにアヤナリワード1ポイントを獲得できます。
                  ポイントは、税金およびサービス料加算前の金額を対象とし、小数点以下のポイントは切り捨てとなります。
                </li>
                <li>
                  ポイントは、対象料金でチェックイン、宿泊、および宿泊料金の支払いを受けた会員にのみ対象となります。
                  （1）各部屋がメンバーによって支払われ、すべての料金がチェックアウト時に決済された場合、（2）メンバーが一部屋を占有する場合、メンバーは参加施設で同じ滞在日数で5部屋までポイントを獲得できます。
                </li>
                <li>
                  アヤナ
                  リワードポイントの有効期限は発行日から10年間（120ヶ月）です
                </li>
                <li>
                  すべてのホテルでは、ご利用料金に関わらず、第三者サイト（GDSを除く）よりホテルの予約をされた場合には、宿泊料はポイントの対象とはなりません。その際、客室料金以外にご利用になられた項目に関しては、ポイントの対象となります。
                </li>
                <li>
                  <span
                    style={{
                      color: "rgb(34, 34, 34)",
                      fontFamily: "arial, sans-serif",
                      fontSize: "16px",
                      whiteSpace: "pre-wrap",
                      backgroundColor: "rgb(248, 249, 250)",
                    }}>
                    ゲストは、会員になった後にポイントを獲得する資格があります。
                  </span>
                  ポイントは、チェックアウト後
                  最大7営業日以内に会員様のアカウントに加算されます。
                </li>
                <li>
                  ポイント対象項目： ・アヤナミッドプラザ ジャカルタ
                  Oポイント対象の客室価格
                  ほとんどの客室料金に対してポイントを獲得できます。対象外の料金は、社員料金、コミッション対象料金、ホールセラー料金、無料宿泊ほか、マスターアカウント精算のもの、クルーメンバー料金、キャッシュ＆ポイント料金、グループ料金となります。対象外の客室料金でご宿泊の場合でも、客室料金以外の項目の料金についてはポイントの対象となります。各ホテルは、ポイント対象外の料金については独自に表示する責任があります。
                  O飲食料金 、除外するHonZen or KaDo。Oメンバー以外の飲食料金
                  <ul>
                    <li>
                      Oノーショー（無連絡での不泊）、アーリーデパーチャー（予約期間を短縮してのチェックアウト）、キャンセル時に生じた料金。
                    </li>
                    <li>
                      デロニクスホテル カラワン
                      <br />
                      Oご利用料金すべてがポイント対象 (excluding Sakana
                      Restaurant charges)
                    </li>
                    <li>
                      アヤナリゾート＆スパ バリ、ザ ヴィラズ アット
                      アヤナリゾート バリ、リンバジンバラン バリbyアヤナ&nbsp;
                      <br />
                      Oホテルへ直接予約された客室料金
                    </li>
                    <li>
                      アヤナ ラコディア号&nbsp;
                      <br />
                      直接お申込みのプログラムパッケージおよび宿泊チャーターに限り適用されます。特別料金や、アルコールを含む追加利用分には適用されません。
                    </li>
                  </ul>
                  <br />
                  **サービス料、税金ほか、諸雑費はアヤナリワードのポイント対象外です。
                </li>
                <li>
                  ポイント状況についてメンバーはアヤナリワードのポータルサイト-
                  ayanarewards.com
                  -　にログインし、リワードポイント残高や状況を確認できます。またinfo@ayanarewards.comよりアヤナリワード会員サービスマネージャーまでEメールにて確認することができます。
                </li>
                <li>
                  万一アヤナリワード会員アカウントにポイントが加算されていない場合、ご滞在のチェックアウト日より30日以内に、アヤナリワード会員サービスマネージャーまでご連絡ください。期限日までにご連絡がない場合、アヤナリワードポイントのご提供は出来かねますことご了承ください。会員様は、チェックアウト後より7営業日後にポイントを確認できます。アヤナリワードポイントが未反映である場合はEメール　ayanarewards@midplaza.comよりアヤナリワード会員サービスまでご連絡ください。
                </li>
                <li>
                  <span
                    style={{
                      color: "rgb(29, 28, 29)",
                      fontFamily: "Slack-Lato, appleLogo, sans-serif",
                      fontSize: "15px",
                      fontVariantLigatures: "common-ligatures",
                      backgroundColor: "rgb(255, 255, 255)",
                    }}>
                    アヤナリワードは、メンバーのアカウントへ誤ってポイントが加算された場合、その分のポイントを残高より差し引く権利を有しています。
                  </span>
                </li>
              </ol>
              <h3>リワードの請求・引き換え</h3>
              <ol>
                <li>
                  会員はポータルサイトayanarewards.comのリワード/引き換えセクションで、アヤナリワードのポイントをリワードと交換できます。その際メンバーのユーザーIDとパスワードに加え、アヤナリワードでは個人情報について確認する権利を有します。
                </li>
                <li>
                  各リワードには最低リワードポイントが設けられています。詳細についてはウェブサイトのリワードチャートをご覧ください。
                </li>
                <li>
                  報酬を請求する前に、各報酬に記載されている利用規約を常に参照することは会員の義務です。
                </li>
                <li>
                  夜間滞在の特典については、ご希望の滞在日を記載したメールをinfo@ayanarewards.comに送信して事前予約が必要です。ハイシーズン中は追加料金がかかる場合があります。
                </li>
                <li>
                  すべての特典は返金不可およびキャンセル不可です。会員のポイントは引き換え時に差し引かれ、引き換えポリシーに従います。
                </li>
                <li>
                  特典交換日から最大6ヶ月間有効です。未使用の報酬は、会員の口座への入金または現金との交換のために返金することはできません。
                </li>
                <li>
                  アヤナリワードは、参加パートナーが行うプログラムの停止や取り消しなどについて責任を負うものではありません。
                </li>
              </ol>
              <h3>一般規則</h3>
              <ol>
                <li>
                  アヤナリワード会員E-カードはアヤナリワードにおいてのみ有効です。カードのご利用に際してはここに記載する一般規則及び規定の元で特典やサービスをご提供します。
                </li>
                <li>
                  個人情報に変更があった場合、会員はご自身でアヤナリワード会員のポータルサイトにてその旨ご変更いただくか、アヤナリワード会員サービスまで書面でご連絡ください。
                </li>
                <li>
                  リワード認定書の保管は会員個人の責任によります。リワードカードの認定書の代替品はご用意しておりません。
                </li>
                <li>
                  アヤナリワード会員サービスオフィスでは、入力エラーなどによるメンバー明細やアヤナリワードポイントの残高などを変更する権利を有します。
                </li>
                <li>
                  アヤナリワードは規則及び規約、プログラム特典内容、特別オファーなどを予告なく変更する権利を有します。アヤナリワードはアヤナリワードプログラムをいつでも終了する権利を有します。
                </li>
                <li>
                  アヤナリワードポイントの獲得やリワードご利用については、法規制に準じます。
                </li>
                <li>
                  アヤナリワードでご用意する特典やリワードには税金が加算されます。特典やリワードをご利用の際に発生する税金についてはメンバーご個人の責任となりますこと、ご了承ください。
                </li>
                <li>
                  アヤナリワードは、リワードの特典や交換した商品及びサービスの品質、内容、全てにおいて責任（結果として生じた損害を含む）を負うものではなく、保証は一切行いません。法律によって暗示された全ての条件、保証またはその他の条項は、適用される法律や規則の許す限り除外されます。会員がリワード証書の利用を譲渡した場合、この条項は譲渡されたメンバーにも同様に適用されます。
                </li>
                <li>
                  参加ホテル及び/またはアヤナリワードは、市場の動向に合わせてアヤナリワードのポイント価値を変更する権利を有します。
                </li>
                <li>
                  アヤナリワードもしくはリワードのご利用に際して、悪用もしくは乱用が発生した場合、参加ホテル及び/又はアヤナリワード参加パートナーは適正な法的手段を講じます。
                </li>
                <li>このプログラムは違法行為において無効とされます。</li>
                <li>
                  アヤナリワードロイヤルティープログラムは、参加ホテルが運営するプログラムです。サービス提供者はメンバー明細へアクセスする場合がありますこと、ご了承ください。
                </li>
                <li>
                  プログラムの規則及び規約についてはアヤナリワード単独の裁量によるものです。万一紛争が生じた場合、会員はインドネシアの裁判所の管轄権に従うことに同意するものとします。
                </li>
              </ol>
            </div>
          </div>
        </Layout>
      );
    }
  }
}
